import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function LawsuitsBottom(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <div className="">
                    <h2>Tylenol Autism Lawsuits</h2>
                    <p>
                        The growing body of evidence suggesting a link between
                        Tylenol usage during pregnancy and an increased risk of
                        autism is leading more families to seek legal recourse.
                        At Harman Law Firm, the legal team is actively
                        investigating Tylenol autism lawsuits. Parents of
                        children with ASD, whose mothers used Tylenol or
                        acetaminophen during pregnancy, might have a legal case.
                        If a connection is established, they could be eligible
                        for compensation covering medical costs, lost income,
                        specialized care, and other related damages.
                    </p>
                </div>
                <div className="relative  lg:w-[362px] lg:h-[429px] w-[300px] h-[390px] mb-4 md:mb-0">
                    <Image
                        src="/tylenolautism/assets/images/tylenolAutismImg3.png"
                        alt="roundup"
                        layout="fill"
                    />
                </div>
            </div>
        </div>
    );
}
